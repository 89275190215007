<template>
  <CContainer class="d-flex align-items-center" style="height: 75vh !important">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">
              <CIcon
                style="width: 3rem; height: 4rem"
                :content="$options.freeSet.cilMeh"
              />
            </h1>
            <h4 class="pt-3">Ups! lo sentimos {{ nombreUsuario }}.</h4>
            <p class="text-muted">
              Usted no dispone de este modulo, contacte con su administrador
              para solicitarlo.
            </p>
          </div>
          <!--<CInput
          class="mb-3"
          placeholder="What are you looking for?"
        >
          <template #prepend-content><CIcon name="cil-magnifying-glass"/></template>
          <template #append>
            <CButton color="info">Search</CButton>
          </template>
        </CInput>-->
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { freeSet } from "@coreui/icons";
export default {
  name: "ModuloNoDisponible",
  freeSet,
  data() {
    return {
      nombreUsuario: JSON.parse(this.$cookie.get("userLogginToken"))
        .nombreUsuario,
    };
  },
};
</script>
